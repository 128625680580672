<template>
  <div>
    <div class="form-group row">
      <div class="col-lg-6 mb-5">
        <label>{{ $t('treasuries.balance') }}<span class="text-danger">*</span></label>
        <input type="number" v-model="data.balance" class="form-control" :class="validation && validation.balance ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.balance" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.balance[0] }}
                            </span>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('treasuries.currency') }}<span class="text-danger">*</span></label>
        <div class="input-group">
          <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
            <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
          </select>
          <div class="input-group-prepend">
            <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
          </div>
        </div>
        <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('exchange_rate') }}<span class="text-danger">*</span></label>
        <input type="number" v-model="data.exchange_rate" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
        <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
      </div>

      <div class="col-lg-6 mb-5">
        <label>{{ $t('status') }}<span class="text-danger">*</span></label>
        <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
          <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
        </select>
        <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
            {{ validation.status[0] }}
        </span>
      </div>


    </div>
    <div class="pl-0 pr-0 pb-0">
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>


      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <hr>
      </div>
      <div class="col-lg-12">

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table_open_balance">
<!--          v-if="$can('treasuries.update')"-->
          <template slot="status" slot-scope="props">
            <button  @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{$t('change_status')}} ({{ props.row.status_name }})
            </button>
          </template>
          <template slot="actions" slot-scope="props">
            <!--            v-if="$can('treasuries.update')"-->
            <v-btn icon color="pink" @click="getForEdit(props.row.id)" v-if="props.row.status != 2">
              <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2">mdi-pencil</v-icon>
            </v-btn>
            <!--            v-if="$can('treasuries.delete')"-->
            <v-icon small class="text-danger" v-if="props.row.status != 2" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>

          </template>
        </v-server-table>
      </div>
    </div>


    <b-modal ref="status-balance-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideBalanceModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerBalanceId"
                          :current-status="statusId"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>

  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";
import Vue from "vue";

export default {
  name: "treasury-open-balance-form",
  components: {'change-status-form': ChangeStatusFrom,},

  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    currentId: null,
    treasuryOpenBalanceId: null,

    innerId: null,

  },
  data() {
    return {
      mainRoute: 'finances/treasuries-open-balance',
      mainRouteDependency: 'base/dependency',
      routeChangeStatus : 'finances/treasury-open-balance/change-status',

      data: {
        treasury_id: null,
        currency_id: null,
        balance: null,
        exchange_rate: null,
        status: 1,
      },
      validation: null,
      currencies: [],
      status_list: [],
      isEditing: false,
      idEdit: null,

      /***
       * list
       */
      filters: {
        treasury_id: null,
      },
      filter: {
        sortBy: 'id',
      },
      columns: ['balance', 'currency_name', 'exchange_rate', 'created_at', 'status', 'actions'],

      innerBalanceId: null,
      statusId: null,

    };
  },
  watch: {
    currentId: function (val) {
      if (val) {
        this.data.treasury_id = this.currentId;
        this.filters.treasury_id = this.currentId;
      }
    },
    // treasuryOpenBalanceId: function (val) {
    //   if (val) {
    //     this.idEdit = this.treasuryOpenBalanceId;
    //   }
    // },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          balance: that.$t('treasuries.balance'),
          currency_name: that.$t('treasuries.currency'),
          status: that.$t('status'),
          exchange_rate: that.$t('exchange_rate'),
          created_at: that.$t('created_at'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.perPage,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },
    cancel() {
      this.$emit('hide-modal');
      this.data.treasury_id = null;
      this.resetAfterSave();
      this.finishedSave();
    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.resetAfterSave();
        this.getFetch();
        Vue.prototype.$postStatus = true;
      }).catch(error => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
        Vue.prototype.$postStatus = true;
      });
    },
    update() {
      ApiService.put(`${this.mainRoute}/${this.idEdit}`, {
        ...this.data,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.resetAfterSave();
        this.getFetch();
        Vue.prototype.$postStatus = true;
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
        Vue.prototype.$postStatus = true;
      });
    },
    resetAfterSave() {
      this.data.currency_id = null;
      this.data.balance = null;
      this.data.exchange_rate = null;
      this.data.status = 1;

      this.validation = null;

      this.isEditing = false;
      this.idEdit = null;
    },

    async getCurrencies() {
      await ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val;
      });
    },
    getStatus() {
      ApiService.get(`${this.mainRouteDependency}/treasury-open-balance-status`).then((response) => {
        this.status_list = response.data.data;
      });
    },

    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.idEdit}`).then((response) => {
        this.isEditing = true;
        this.data.currency_id = response.data.data.currency_id;
        this.data.balance = response.data.data.balance;
        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.status = response.data.data.status;

      });
    },
    getForEdit(id) {
      this.idEdit = id;
      this.getData();
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    getFetch() {
      this.$refs.table_open_balance.refresh();
    },


    showBalanceModal() {
      this.$refs['status-balance-modal'].show()
    },
    hideBalanceModal() {
      this.$refs['status-balance-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerBalanceId = id;
      this.statusId = status;
      this.showBalanceModal();
    },
    getDataAfterChangeStatus() {
      this.innerBalanceId = null;
      this.statusId = null;
      this.getFetch();
    },
  },

  mounted() {
    this.getCurrencies();
    this.getStatus();
    // this.idEdit = this.treasuryOpenBalanceId;

    // if (this.treasuryOpenBalanceId) {
    //   this.getData();
    // }
  },
  created() {
    this.data.treasury_id = this.currentId;
    this.filters.treasury_id = this.currentId;
  }
};
</script>