<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('treasuries.create')" to="/finances/treasuries/create" class="btn btn-primary font-weight-bolder">
                <v-icon>mdi-plus</v-icon>
                {{ $t('treasuries.add_treasuries')}}
            </router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row mb-5">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="code">{{$t('treasuries.name')}}</label>
                            <input v-model="filters.name" type="text" id="code" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('treasuries.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('treasuries.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('treasuries.deposit')}}</label>
                            <select name="" id="deposit" v-model="filters.deposit" class="custom-select">
                                <option v-for="row in deposits" :value="row.id" :key="row.id">{{ row.title }}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('treasuries.withdraw')}}</label>
                            <select name="" id="withdraw" v-model="filters.withdraw" class="custom-select">
                                <option v-for="row in withdraws" :value="row.id" :key="row.id">{{ row.title }}</option>
                            </select>
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">{{$t('status')}}</label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>


                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-body">

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
<!--                       -->
                        <b-form-checkbox  v-if="$can('treasuries.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox  v-else size="lg" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <!--               -->
                        <v-btn icon color="pink" v-if="$can('treasuries.update')" :to="`/finances/treasuries/edit/${props.row.id}`">
                            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2">mdi-pencil</v-icon>
                        </v-btn>
                        <!--               -->
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('treasuries.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/finances/treasuries/view/${props.row.id}`">
                            <v-icon small class="mr-2">mdi-eye</v-icon>
                        </v-btn>

                      <button  v-if="$can('treasuries.open_balance')" class="btn btn-secondary m-btn m-btn--icon w-auto"  @click="addOpenBalance(props.row.id)" :key="props.row.id">
                        {{$t('treasuries.open_balance')}}
                      </button>
                    </template>
                </v-server-table>

            </div>
        </div>
      <b-modal ref="open-balance-modal" size="xl" hide-footer :title="$t('treasuries.open_balance')">
        <treasury-open-balance-form @hide-modal="hideModal()"
                                :current-id="innerId"
                                @handling-data="getDataAfterSave">
        </treasury-open-balance-form>
      </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TreasuryOpenBalanceForm from "@/view/content/forms/TreasuryOpenBalanceForm.vue";

    export default {
        name: "index-treasuries",
      components: {"treasury-open-balance-form": TreasuryOpenBalanceForm},
        data() {
            return {
                mainRoute: 'finances/treasuries',
                routeChangeStatus: 'finances/treasury/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    name: null,
                    deposit: null,
                    from_date: null,
                    to_date: null,
                    status: null,
                    withdraw: null,
                },
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                columns: ['name', 'currency_name', 'manager_name', 'created_date','status', 'actions'],
                data: [],

              innerId: null,
              treasuryOpenBalanceId: null,

                deposits: [],
                withdraws: [],
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('treasuries.name'),
                        currency_name: that.$t('treasuries.currency'),
                        manager_name: that.$t('treasuries.manager'),
                        created_date: that.$t('treasuries.created_date'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.treasuries_management")}]);
            this.getDeposit();
            this.getWithdraw();
        },
        methods: {
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.name = null;
                this.filters.deposit = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.status = null;
                this.filters.withdraw = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            actionDelete(item) {
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            getDeposit() {
                ApiService.get(this.mainRouteDependency + "/deposit_list").then((response) => {
                    this.deposits = response.data.data;
                });
            },

            getWithdraw() {
                ApiService.get(this.mainRouteDependency + "/withdraw_list").then((response) => {
                    this.withdraws = response.data.data;
                });
            },
            changeStatus(id, status) {
                ApiService.patch(`${this.routeChangeStatus}/${id}` , {
                    is_active: (status ? 1 : 0),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },

          showModal() {
            this.$refs['open-balance-modal'].show()
          },
          hideModal() {
            this.$refs['open-balance-modal'].hide();
          },
          addOpenBalance(id) {
            this.innerId = id;
            // this.treasuryOpenBalanceId = treasuryOpenBalanceId;
            this.showModal();
          },
          getDataAfterSave() {
            this.innerId = null;
            // this.treasuryOpenBalanceId = null;
            this.doFilter();
          },
        },
    };
</script>
